<template>
  <div class="article-box"   >
    <div class="title-content">个人会员入会申请</div>
    <el-form :inline="true" :model="searchData">
      <el-form-item label="">
        <el-input v-model="searchData.name" style="width: 200px" placeholder="请输入单位全称或账号"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select clearable v-model="searchData.state" style="width: 200px" placeholder="请选择状态">
          <el-option v-for="(v, k) in memberState" :key="k" :label="v" :value="k"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="medium" icon="el-icon-search" @click="searchHandle">搜索</el-button>
        <el-button plain size="medium" icon="el-icon-refresh-left" @click="searchHandle">重置</el-button>
      </el-form-item>
    </el-form>

    <div>
      <!-- 表格 -->
      <el-table border :data="articleList" highlight-current-row header-row-class-name="table-header-class"
        :header-cell-style="{ 'background-color': '#F7F8FA' }" style="width: 100%;">
        <el-table-column type="index" width="55" label="序号"></el-table-column>
        <el-table-column prop="loginName" label="手机号（账号)" width="100">
        </el-table-column>
        <el-table-column prop="createDate" label="申请时间" >
        </el-table-column>
        <el-table-column prop="userNickName" label="姓名" >
        </el-table-column>
        <el-table-column prop="idCard" label="身份证号" >
        </el-table-column>
        <el-table-column prop="unitName" label="单位" >
        </el-table-column>
        <el-table-column prop="position" label="职务" >
        </el-table-column>
        <el-table-column prop="orgNickName" label="活动参加分支机构" width="160">
        </el-table-column>
        <el-table-column prop="status" label="状态" >
          <template slot-scope="scope">
            <span>{{ memberState[scope.row.state] }}</span>
            <el-tooltip v-if="
              (scope.row.state === 3 || scope.row.state === 4) &&
              scope.row.remarks
            " :content="scope.row.remarks" placement="top">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="操作" width="150">
          <template slot-scope="scope">
            <span class="text-btn" @click="getDetail(scope.row.id, scope.row.userId)" >查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination style="text-align: right; padding-top: 20px" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80]"
        layout="total, sizes, prev, pager, next, jumper" :page-size="pageSize" :current-page="pageNo"
        :page-count="totalPage" :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { memberState } from "@/assets/model/interface";
import {
  InstitutionPersonalMemberList
} from "@/http/api/InstitutionApi";

export default {
  name: "ApplyUnit",
  data() {
    return {
      itemDetail: {
        unitPdfs: [],
        others: [],
        businessLicenses: [],
        unitEnterpriseVo: {},
        unitCommunityVo: {
          president: [],
          vicePresident: [],
          secretary: [],
          viceSecretary: [],
        },
      },
      title: "",
      DetailDialog: false,
      totalPage: 0, // 总页数
      totalCount: 0, // 总条数
      pageNo: 1, // 当前页数
      pageSize: 10, // 每页几条
      memberState: memberState,
      showsss: true,
      loading: true,
      searchData: {
        name: "",
        state: "",
      },

      deleteDialogVisibles: false,
      articleList: [],
      setStateDialog: {
        show: false,
        loading: false,
        title: "同意入会",
        state: 2,
        id: "",
        text: "",
        data: {
          orgName: "",
          file1Info: "",
          file2Info: "",
          file3Info: "",
          file4Info: "",
          file1Info1: "",
          file2Info2: "",
          file3Info3: "",
          file4Info4: "",
        },
      },
      list: {
        memberUnitVo: {
          unitEnterpriseVo: {},
        },
        unitReprstVo: {},
      },
      memberForResumes: [],
      // list:[],
      lists: {
        memberUnitVo: {
          memberInstitutions: [],
          unitCommunityVo: {
            memberForCommPositionVos: [],
          },
        },
        unitReprstVo: {
          memberForResumes: [{ position: "", time: "", unit: "" }],
        },
      },
      detailInfo: {
        follow: [], //关注分支机构
        partake: [], //参与分支机构
      },
    };
  },

  mounted() {
    this.getList()
  },
  methods: {
    // 分页器点击
    handleSizeChange(val) {
      this.pageNo = 1;
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getList();
    },

        // 获取个人会员入会信息
    async getList() {
      const prame = {
        type: 1,
        pageNo: this.pageNo || 1,
        pageSize: this.pageSize || 10,
        keyword:this.searchData.name,
        state:this.searchData.state
      }
      let resp = await InstitutionPersonalMemberList(prame)
      if (resp.code === '000') {
        this.totalPage = resp.result.pages;
        this.totalCount = resp.result.total;
        this.pageNo = resp.result.current;
        this.articleList = resp.result.records
      } else {
        this.$Message.error("获取数据失败");
      }
    },

    // 查询和重置按钮
    searchHandle(){
       this.getList()
    },

    // 查看详情
    getDetail(id) {
      this.$router.push({
        path: "/institution/member/personageapply/detail",
        query: { id: id },
      });
      // console.log(id,'查看详情');
    },
  },
};
</script>

<style lang="less" scoped>
.el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
  border-color: #d3ddef;
}
.el-descriptions__body .el-descriptions__table .el-descriptions-item__label.is-bordered-label {
  width: 150px;
  background-color: #f7f9ff !important;
  color: #5f717d !important;
}

.el-descriptions-item__content {
  width: 230px;
}
.article-box {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .2);
  border-radius: 5px;
  padding: 20px;
}
// 这是我从后台抄来的article
</style>